<div class="layout">

    <div class="modulo-layout">

        <div class="modulo">
    
            <div class="modulo-conteudo centered">

                <div class="menu-blocos centered is-align-items-center" style="gap: 35px;">

                    @for (item of modulos; track $index) {

                        <div class="card bloco mb-0 overlay">
    
                            <div 
                                class="card-content centered pointer" 
                                style="width: 140px; height: 140px;"
                                [routerLink]="[item.url]"
                                >
                                {{item.label}}
                            </div>
    
                        </div>
                    }
        
                </div>
    
            </div>
            
        </div>

    </div>

</div>
