import { Route } from '@angular/router';
import { AuthComponent } from './applications/auth/auth.component';
import { QuestionariosComponent } from './applications/questionarios/questionarios.component';
import { HomeComponent } from './applications/home/home.component';
import { TarefasComponent } from './applications/tarefas/tarefas.component';
import { MonitoramentoComponent } from './applications/monitoramento/monitoramento.component';
import { PreenchimentoComponent } from './applications/preenchimento/preenchimento.component';
import { AuthGuard } from './guards/auth.guard';
import { ConfiguracoesComponent } from './applications/configuracoes/configuracoes.component';

export const APP_ROUTES: Route[] = [
    {
        'path':'',
        'component': HomeComponent,
    },
    {
        'path':'auth',
        'loadChildren':() => import('./applications/auth/auth.routes').then(c => c.AUTH_ROUTES),
        'component': AuthComponent,
    },
    {
        'path':'questionarios',
        'loadChildren':() => import('./applications/questionarios/questionarios.routes').then(c => c.QUESTIONARIOS_ROUTES),
        'component': QuestionariosComponent,
        'canActivate': [AuthGuard],
    },
    {
        'path':'configuracoes',
        'loadChildren':() => import('./applications/configuracoes/configuracoes.routes').then(c => c.CONFIGURACOES_ROUTES),
        'component': ConfiguracoesComponent,
        'canActivate': [AuthGuard],
    },
    {
        'path':'tarefas',
        'loadChildren':() => import('./applications/tarefas/tarefas.routes').then(c => c.TAREFAS_ROUTES),
        'component': TarefasComponent,
        'canActivate': [AuthGuard],
    },
    {
        'path':'monitoramento',
        'loadChildren':() => import('./applications/monitoramento/monitoramento.routes').then(c => c.MONITORAMENTO_ROUTES),
        'component': MonitoramentoComponent,
        'canActivate': [AuthGuard],
    },
    {
        'path':'preenchimento',
        'loadChildren':() => import('./applications/preenchimento/preenchimento.routes').then(c => c.PREENCHIMENTO_ROUTES),
        'component': PreenchimentoComponent,
        'canActivate': [AuthGuard],
    },
]
