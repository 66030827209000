import { AfterViewInit, Component, inject, OnInit, viewChild } from '@angular/core';
import { AppModule } from '../../app.module';
import { LayoutComponent } from '../../components/layout/layout.component';
import { AuthService } from '../../services/auth/auth.service';

@Component({
    'standalone': true,
    'imports': [AppModule, LayoutComponent],
    'selector': 'questionarios',
    'templateUrl':  './configuracoes.component.html',
})
export class ConfiguracoesComponent implements OnInit, AfterViewInit{

    layout = viewChild(LayoutComponent)

    ngOnInit(){

    }

    ngAfterViewInit(){

    }

}

