<layout [content]="true">

    <div class="modulo-layout">

        <div class="modulo-menu">
            
            <div class="modulo-menu-title">
                Configurações
            </div>
            
            <div class="modulo-menu-items">
                <!--
                <div class="item" routerLink="entidade" routerLinkActive="is-active">
                    Entidade
                </div>
                -->
                <div class="item" routerLink="usuarios" routerLinkActive="is-active">
                    Usuários
                </div>
            </div>
    
        </div>
        
        <div class="modulo">
    
            <div class="modulo-conteudo">
    
                <router-outlet/>
    
            </div>
            
        </div>
    
    </div>

</layout>