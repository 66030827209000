import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { AppModule } from '../../../../app.module';
import { ModalService } from '../../../../components/modal/modal.service';
import { AuthService } from '../../../../services/auth/auth.service';

@Component({
    standalone: true,
    imports: [AppModule],
    selector: 'auth-usuario',
    templateUrl: './auth-usuario.component.html',
})
export class AuthUsuarioComponent implements OnInit{

    @Output() concluido = new EventEmitter<string>()

    modal = inject(ModalService)
    auth = inject(AuthService)

    ngOnInit(){

    }

    concluir(){
        this.modal.close()
    }

    logout(){
        this.auth.logout()
    }

}
