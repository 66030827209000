import { Component, inject, Injector, OnInit, ViewContainerRef } from '@angular/core';
import { RouterModule } from '@angular/router';
import { setGlobalInjectionContext } from './facades';
import { GlobalModalControllerService } from './services/modal/global-modal-controller.service';
import { MessagesControllerService } from './services/messages/messages-controller.service';
import { LoaderIndicatorComponent } from './components/loader/loader-indicator.component';

@Component({
    standalone: true,
    imports: [RouterModule, LoaderIndicatorComponent],
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit{

    messagesController = inject(MessagesControllerService)
    modalController = inject(GlobalModalControllerService)
    viewContainerRef = inject(ViewContainerRef)
    injector = inject(Injector)

    ngOnInit(){
        
        setGlobalInjectionContext(this.injector)

        this.modalController.initialize(this.viewContainerRef)
        this.messagesController.initialize(this.viewContainerRef)

    }

    constructor(){
    }

}
