import { AfterViewInit, Component, OnInit, viewChild } from '@angular/core';
import { AppModule } from '../../app.module';
import { LayoutComponent } from '../../components/layout/layout.component';

@Component({
    'standalone': true,
    'imports': [AppModule, LayoutComponent],
    'selector': 'questionarios',
    'template': `
        <layout></layout>
    `
})
export class QuestionariosComponent implements OnInit, AfterViewInit{

    layout = viewChild(LayoutComponent)

    ngOnInit(){

    }

    ngAfterViewInit(){

    }

}

