import { Component, inject, OnInit } from '@angular/core';
import { AppModule } from '../../app.module';
import { MODULOS } from '../../configs/modulos';
import { AuthService } from '../../services/auth/auth.service';

@Component({
    standalone: true,
    imports: [AppModule],
    selector: 'home',
    templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit{

    modulos = Object.values(MODULOS)
    auth = inject(AuthService)

    ngOnInit(){

    }

}
