import { Component } from '@angular/core';
import { AppModule } from '../../app.module';

@Component({
    standalone: true,
    imports: [AppModule],
    selector: 'auth',
    template: `<router-outlet/>`
})
export class AuthComponent {
}
