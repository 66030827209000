import { Component } from '@angular/core';
import { AppModule } from '../../app.module';
import { LayoutComponent } from '../../components/layout/layout.component';

@Component({
    standalone: true,
    imports: [AppModule, LayoutComponent],
    selector: 'tarefas',
    template: `
        <layout></layout>
    `
})
export class TarefasComponent {
}

