import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth/auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        const auth = inject(AuthService)
        const new_request = request.clone({
            'setHeaders': {
                'Authorization': auth.is_logged ? `Bearer ${auth.dados.jwt}` : ''
            }
        });

        return next.handle(new_request)

    }

}