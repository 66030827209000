<div class="layout" [ngClass]="{'show-menu':show_menu}">

    <div class="button-menu-mobile" (click)="show_menu = !show_menu">
        aaa
    </div>

    <div class="modulos">
        
        <div class="lista-modulos">

            @for (modulo of modulos; track $index) {

                @if( auth.hasPermission(modulo.permissao) ){
                    <div class="button-modulo" [routerLink]="['/', modulo.url]" routerLinkActive="is-active">
                        <i [class]="modulo.icone"></i>
                        <div class="button-modulo-descricao">
                            <div>
                                <div class="label">
                                    {{modulo.label}}
                                </div>
                                <div class="descricao">
                                    {{modulo.descricao}}
                                </div>
                            </div>
                        </div>
                        @if( modulos_notificacoes[modulo.key] && modulos_notificacoes[modulo.key]() ){
                            <div class="notifications">
                            </div>
                        }
                    </div>
                }

            }

        </div>

        <div class="espacamento">
            &nbsp;
        </div>
        
        <div class="configuracoes">

            @if( auth.hasPermission('MODULO_CONFIGURACOES') ){

                <div class="button-modulo" [routerLink]="['/', 'configuracoes']" routerLinkActive="is-active">
                    <i class="fa-solid fa-gear"></i>
                </div>

            }
            <div class="button-modulo" (click)="visualizarPerfil()">
                <i class="fa-solid fa-circle-user"></i>
            </div>
        </div>

    </div>
    
    <div class="modulo">

        @if( content ){
            <ng-content/>
        }@else{
            <router-outlet/>
        }
        
    </div>

</div>