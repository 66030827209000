import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from 'ngx-localstorage';
import { catchError, of, switchMap, tap } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';

export const AuthGuard = (route:ActivatedRouteSnapshot, state:RouterStateSnapshot) => {

    const router = inject(Router)
    const auth = inject(AuthService)
    const storage = inject(LocalStorageService)

    const tryToLoginByToken = () => {
        
        const token = storage.get<string|undefined>('token')

        if( !token ){
            return of(false)
        }

        return auth.loginByToken(token)

    }

    return of(auth.is_logged).pipe(
        switchMap(is_logged => is_logged ? of(true) : tryToLoginByToken()),
        tap(dados => {
            if(!dados){
                router.navigate(['/auth/login'])
            }
        }),
        catchError(err => (
            of(false)
        ))
    )

}