import { Injectable, NgZone } from '@angular/core';

@Injectable({providedIn: 'root'})
export class LoaderIndicatorService {

    protected cont = 0
    protected interval:NodeJS.Timeout|null = null
    visivel = false

    constructor(
        readonly zone:NgZone
    ){

    }

    show(){

        this.cont = this.cont + 1

        if( this.cont === 1 ){

            this.interval = setTimeout(() => {

                if( this.cont >= 1 ){
                    this.visivel = true
                }

                this.interval = null

            }, 100)

        }

    }

    hide(){

        this.cont = this.cont - 1

        if( this.cont <= 0 ){

            this.cont = 0

            if( this.interval ){
                clearInterval(this.interval)
            }

            setTimeout(() => {

                if( this.cont === 0 ){
                    this.visivel = false
                }

            }, 200)

        }

    }

}