import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, throwError } from "rxjs";
import { LoaderIndicatorService } from "../services/loader/loader-indicator.service";

@Injectable()
export class ApiHttpInterceptor implements HttpInterceptor{

    constructor(
        protected loading:LoaderIndicatorService
    ){

    }

    intercept(request: HttpRequest<any>, next: HttpHandler){

        const show_loading = () => request.body.__loading !== false ? this.loading.show() : undefined
        const hide_loading = () => request.body.__loading !== false ? this.loading.hide() : undefined

        show_loading()

        return next.handle(request)
            .pipe(
                catchError((err) => {
                    hide_loading()
                    return throwError(() => err)
                })
            )
            .pipe(
                map(event => {

                    if( event instanceof HttpResponse || event instanceof HttpErrorResponse ){
                        hide_loading()
                    }

                    return event
                }),
            )

    }

}