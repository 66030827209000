import { Injectable } from '@angular/core';

@Injectable({
    'providedIn': null,
})
export class LayoutService {
    
    constructor() { }
    
}

