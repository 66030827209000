import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { ApplicationConfig, LOCALE_ID, provideZoneChangeDetection } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, RouteReuseStrategy, withComponentInputBinding, withRouterConfig } from '@angular/router';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { provideNgxLocalstorage } from 'ngx-localstorage';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { APP_ROUTES } from './app.routes';
import { CustomRouteReuseStrategy } from './configs/route-reuse-strategy';
import { ApiHttpInterceptor } from './interceptors/api.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AuthService } from './services/auth/auth.service';
import { LoaderIndicatorService } from './services/loader/loader-indicator.service';

registerLocaleData(localePt)

export const APP_CONFIG: ApplicationConfig = {
    providers: [
        provideCharts(withDefaultRegisterables()),
        provideEnvironmentNgxMask(),
        provideAnimations(),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(
            APP_ROUTES,
            withComponentInputBinding(),
            //withRouterConfig({
            //    'onSameUrlNavigation': 'reload',
            //    'urlUpdateStrategy': 'eager',
            //}),
        ),


        provideHttpClient(
            withInterceptorsFromDi() 
        ),
        {'provide': HTTP_INTERCEPTORS, 'useClass': AuthInterceptor, 'multi': true, 'deps':[AuthService]},
        {'provide': HTTP_INTERCEPTORS, 'useClass': ApiHttpInterceptor, 'multi': true, 'deps':[LoaderIndicatorService]},
        //{'provide': RouteReuseStrategy, 'useClass': CustomRouteReuseStrategy},
        {'provide': LOCALE_ID, 'useValue': 'pt-BR' },
        provideNgxLocalstorage({
            'prefix': 'sig',
            'delimiter': '.'
        })
    ],
};
