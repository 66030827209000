import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { AppModule } from '../../app.module';
import { LoaderIndicatorService } from '../../services/loader/loader-indicator.service';

@Component({
    standalone: true,
    selector: 'loader-indicator',
    template: `
        <div class="loader-indicator" *ngIf="service.visivel" [@fade]="service.visivel">
            <div class="content">
                <div class="loader loader-3"><span></span></div>
                Aguarde...
            </div>
        </div>
    `,
    styleUrls:['./loader-indicator.component.scss'],
    imports:[AppModule],
    animations: [
        trigger('fade', [
          transition('void => active', [ // using status here for transition
            style({ opacity: 0 }),
            animate(200, style({ opacity: 1 }))
          ]),
          transition('* => void', [
            animate(100, style({ opacity: 0 }))
          ])
        ])
      ]

})
export class LoaderIndicatorComponent{

    constructor(
        protected service:LoaderIndicatorService
    ){

    }

}