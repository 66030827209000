<div class="modal-page">

    <div
        class="modal-close is-large"
        aria-label="fechar"
        style="position: absolute; right: 20px; top: 20px; z-index: 1;"
        (click)="modal.close()"
    ></div>

    <div class="modal-page-content stack">

        <div class="pb-3 fullfill is-flex is-align-items-center is-justify-content-center stretch">

            <div class="pb-6 is-flex is-justify-content-center is-flex-direction-column is-align-items-center">

                <i class="fa-solid fa-circle-user" style="font-size: 6.5rem;"></i>

                <div class="list-group mt-4">
                    <div class="list-group-item is-size-7">
                        <strong class="mr-1">Usuário:</strong> {{auth.dados.nome_usuario}}
                    </div>
                    <div class="list-group-item is-size-7">
                        <strong class="mr-1">Entidade:</strong> {{auth.dados.cod_entidade}}
                    </div>
                </div>

                <button class="button is-danger is-outlined is-small mt-3 is-fullwidth mb-6" (click)="logout()">
                    Sair do sistema
                </button>

            </div>

        </div>

    </div>

</div>
