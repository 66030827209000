import { Component, inject, Input, OnInit, Signal } from '@angular/core';
import { AppModule } from '../../app.module';
import { MODULOS } from '../../configs/modulos';
import { AuthService } from '../../services/auth/auth.service';
import { NotificacoesService } from '../../services/auth/notificacoes.service';
import { LayoutService } from './layout.service';
import { GlobalModalControllerService } from '../../services/modal/global-modal-controller.service';
import { AuthUsuarioComponent } from '../../applications/auth/components/auth-usuario/auth-usuario.component';

@Component({
    'standalone': true,
    'imports': [AppModule],
    'selector': 'layout',
    'templateUrl': './layout.component.html',
    'providers': [LayoutService],
})
export class LayoutComponent implements OnInit{

    @Input() content = false

    service = inject(LayoutService)
    auth = inject(AuthService)
    notificacoes = inject(NotificacoesService)
    modal_controller = inject(GlobalModalControllerService)

    show_menu = false
    modulos = Object.values(MODULOS)
    modulos_notificacoes:Record<string, Signal<Boolean>> = {
        'questionario': this.notificacoes.tem_notificacao_modulo_questionario,
        'preenchimento': this.notificacoes.tem_notificacao_modulo_preenchimento,
        'tarefas': this.notificacoes.tem_notificacao_modulo_tarefas,
        'monitoramento': this.notificacoes.tem_notificacao_modulo_monitoramento,
    }

    ngOnInit(){
    }

    visualizarPerfil(){

        this.modal_controller.open(
            AuthUsuarioComponent,
            {
                'title': false,
                'size': 'offcanvas',
            }
        ).pipe(
        ).subscribe()

    }

}
